import 'bootstrap';

import Swiper from 'swiper/bundle';
import $ from "jquery";

new Swiper(".mySwiper", {
  slidesPerView: 2.9,
  spaceBetween: 0,
  initialSlide: 1,
  loop: true,
  centeredSlides: true,
  pagination: {
      el: ".swiper-pagination1",
      clickable: true,
  },
  navigation: {
      nextEl: ".swiper-button-next1",
      prevEl: ".swiper-button-prev1",
  },
  breakpoints: {
      // when window width is >= 320px
      315: {
          slidesPerView: 1,
          spaceBetween: 0
      },
      // when window width is >= 480px
      576: {
          slidesPerView: 2.9,
          spaceBetween: 0,
      },
      // when window width is >= 640px

  }
});

new Swiper(".mySwiper2", {
  slidesPerView: 3,
  spaceBetween: 90,
  initialSlide: 1,
  centeredSlides: true,
  loop: true,
  pagination: {
      el: ".swiper-pagination2",
      clickable: true,
  },
  navigation: {
      nextEl: ".swiper-button-next2",
      prevEl: ".swiper-button-prev2",
  },
  breakpoints: {
      // when window width is >= 320px
      315: {
          slidesPerView: 1,
          spaceBetween: 20
      },
      // when window width is >= 480px
      768: {
          slidesPerView: 3,
          spaceBetween: 10,
      },
      // when window width is >= 640px
      992: {
          slidesPerView: 3,
          spaceBetween: 90,
      }
  }
});


$(function () {
  // This will select everything with the class smoothScroll
  // This should prevent problems with carousel, scrollspy, etc...
  $('.scroll').click(function () {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length) {
              $('html,body').animate({
                  scrollTop: target.offset().top
              }, 1000); // The number here represents the speed of the scroll in milliseconds
              return false;
          }
      }
  });
});

$(".menu-icon").click(function () {
  $(".sidebar").toggleClass("active");
  $("body").toggleClass("active");
});
$(".close-menu").click(function () {
  $(".sidebar").removeClass("active");
  $("body").removeClass("active");
});